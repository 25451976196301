import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListGroup } from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import axios from 'axios';
import { API_SERVER } from '../../../../config/constant';
import { LOGOUT } from './../../../../store/actions';
import { Link } from 'react-router-dom';

import NavGroup from './NavGroup';

const NavContent = ({ navigation }) => {
    const account = useSelector((state) => state.account);
    const dispatcher = useDispatch();

    const navItems = navigation.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup layout="vertical" key={'nav-group-' + item.id} group={item} />;
            default:
                return false;
        }
    });

    const handleLogout = () => {
        axios
            .post(API_SERVER + 'users/logout', {}, { headers: { Authorization: `${account.token}` } })
            .then(function (response) {
                // Force the LOGOUT
                //if (response.data.success) {
                dispatcher({ type: LOGOUT });
                //} else {
                //    console.log('response - ', response.data.msg);
                //}
            })
            .catch(function (error) {
                dispatcher({ type: LOGOUT });
                console.log('error - ', error);
            });
    };

    let mainContent = '';
    mainContent = (
        <div className="navbar-content datta-scroll">
            <PerfectScrollbar>
                <ListGroup variant="flush" as="ul" bsPrefix=" " className="nav pcoded-inner-navbar" id="nav-ps-next">
                    {navItems}
                </ListGroup>
            </PerfectScrollbar>
            <ListGroup as="ul" bsPrefix=" " variant="flush" className="pro-body">
                <ListGroup.Item as="li" bsPrefix=" ">
                    <Link to="#" className="dropdown-item" onClick={handleLogout}>
                        <i className="feather icon-log-out" /> Logout
                    </Link>
                </ListGroup.Item>
            </ListGroup>
        </div>
    );

    return <React.Fragment>{mainContent}</React.Fragment>;
};

export default NavContent;
