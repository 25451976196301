const menuItems = {
    items: [
        {
            id: 'navigation',
            title: 'Menu',
            type: 'group',
            icon: 'icon-navigation',
            children: [
                {
                    id: 'dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    url: '/dashboard',
                    icon: 'feather icon-home'
                },
                {
                    id: 'customers',
                    title: 'Customers',
                    type: 'item',
                    url: '/customers',
                    icon: 'feather icon-users'
                },
                {
                    id: 'admintransactions',
                    title: 'Admin Token Transactions',
                    type: 'item',
                    url: '/admin-transactions',
                    icon: 'feather icon-user'
                },
                {
                    id: 'customertransactions',
                    title: 'Customer Token Transactions',
                    type: 'item',
                    url: '/customer-transactions',
                    icon: 'feather icon-credit-card'
                },
                {
                    id: 'refunds',
                    title: 'Refund Request',
                    type: 'item',
                    url: '/refund-requests',
                    icon: 'feather icon-disc'
                },
                {
                    id: 'gamereports',
                    title: 'Game Reports',
                    type: 'collapse',
                    icon: 'feather icon-file-text',
                    children: [
                        {
                            id: 'pappu',
                            title: 'Pappu Picture',
                            type: 'item',
                            url: '/games-report',
                            icon: 'feather icon-image'
                        },
                    ]
                },
            ]
        }
    ]
};

export default menuItems;
