import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FaCoins, FaRegUserCircle } from 'react-icons/fa';
import axios from 'axios';

import { API_SERVER } from '../../../../config/constant';

import { ConfigContext } from '../../../../contexts/ConfigContext';
import * as actionType from '../../../../store/actions';
import logo from '../../../../assets/images/stockist-logo.png';

const NavLogo = () => {
    const configContext = useContext(ConfigContext);
    const { collapseMenu } = configContext.state;
    const { dispatch } = configContext;
    const [stockistBalance, setStockistBalance] = useState(null);
    const [stockistName, setStockistName] = useState(null);

    const account = useSelector((state) => state.account);
    const dispatcher = useDispatch();

    const fetchStockist = async () => {
        try {
            const response = await axios.get(API_SERVER + `stockist/detail`, {
                headers: { Authorization: `${account.token}` }
            });
            const data = response.data;
            setStockistName(data.name);
            setStockistBalance(data.balance);
        } catch (error) {
            if (error.response && error.response.status === 403) {
                dispatcher({ type: actionType.LOGOUT });
            }
            console.error('Error fetching stockists:', error);
        }
    };

    useEffect(() => {
        fetchStockist();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stockistBalance]);

    let toggleClass = ['mobile-menu'];
    if (collapseMenu) {
        toggleClass = [...toggleClass, 'on'];
    }

    return (
        <React.Fragment>
            <div className="navbar-brand header-logo">
                <Link to="/dashboard" className="b-brand">
                    <img className="admin-logo" src={logo} alt="admin logo" />
                    <span className="b-title">Stockist Portal</span>
                </Link>
                <Link
                    to="#"
                    className={toggleClass.join(' ')}
                    id="mobile-collapse"
                    onClick={() => dispatch({ type: actionType.COLLAPSE_MENU })}
                >
                    <span />
                </Link>
            </div>
            <div style={{ display: 'inline-grid' }}>
                <ul className="nav pcoded-inner-navbar    -flush">
                    <li>
                        <a className="nav-link " target="" href="/dashboard/default">
                            <span className="pcoded-micon">
                                <FaRegUserCircle />
                            </span>
                            <span className="pcoded-mtext">
                                <b>{stockistName}</b>
                            </span>
                        </a>
                    </li>
                    <li>
                        <a className="nav-link " target="" href="/dashboard/default">
                            <span className="pcoded-micon">
                                <FaCoins />
                            </span>
                            <span className="pcoded-mtext">
                                <b>{stockistBalance} Tokens</b>
                            </span>
                        </a>
                    </li>
                </ul>
            </div>
        </React.Fragment>
    );
};

export default NavLogo;
